import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { msalConfig, msalScopes } from './authConfig'
import { MsalPlugin } from './plugins/MSALPlugin'

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/styles/styles.css'


Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

const msalOptions = {
  msalConfig: msalConfig,
  msalScopes: msalScopes
};
Vue.use(new MsalPlugin(), msalOptions);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
