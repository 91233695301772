import axios from 'axios';
import { msalPluginInstance } from "../plugins/MSALPlugin"
import router from '../router';

const getTokenAndCallApi = async (config) => {
  return await msalPluginInstance.acquireToken(async (access_token) => {
    // azure swa replaces 'authorization' header with its own so we use custom one
    config.headers = {
      'x-custom-authorization':  `Bearer ${access_token}`
    };
    try {
      return await axios(config);
    } catch (err) {
      if(err.response.status === 403){
        router.push({ name: 'forbidden'});
      } else {
        //do we need to handle error 401? 
      }
      //return data to avoid errors in console
      return err.response;
      //or just rethrow error?
    }
  });
};

export default class DataService {
    /**
    * upgrade License
    *
    * @param userEmail logged in user's email
    */
    upgradeLicense(userEmail) {
        return getTokenAndCallApi({url: '/api/upgradeLicense', method: 'POST', data:
    { email: userEmail }});
    }
}