import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
 state: {
   user: {
        auth: false,
        idToken: '',
        email: '',
        name: ''
   }
 },
 getters: {
   auth: state => {
     return state.user.auth
   }
 },
 // Mutations are the only way to properly change the value of the state object. 
 // An important detail to note is that mutations must be synchronous.
    mutations: {
        changeAuth(state, payload) {
            let isAuth = payload.isAuthenticated();
            state.user.auth = isAuth;
        },
        setUserEmail(state, payload) {
            state.user.email = payload;
        },
        setUserName(state, payload) {
            state.user.name = payload;
        },
        setUserIdToken(state, payload) {
            state.user.idToken = payload;
        }
    },
 actions: {}
});