<template>
    <div class="main text-center">
        <b-container>
            <p>{{message}} <br>
                You can now close this tab.
            </p>
        </b-container>
        
    </div>
</template>

<script>
    export default {
        name: 'logout-page',
        data() {
            return {
            message: 'You have successfully signed out.'
            }
        },
        mounted () {
            // check for errors
            if (this.$route.query.error) {
                this.message = 'Access denied. Please contact your Goodyear administrator for help.'
            } 
        }
    }
</script>
