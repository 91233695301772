<template>
    <main class="main text-center">
        <b-container class="centerContent" v-if="$store.state.user.auth == true">
            <!--Add v-if to display info only when logged in-->
            <b-row align-h="center">
                <b-icon class="icon" icon="person-circle"></b-icon>
            </b-row>
            <b-row align-h="center">
                <p>
                    {{ $store.state.user.name }}
                </p>
            </b-row>
            <b-row align-h="center">
                <p>
                    {{ $store.state.user.email }}
                </p>
            </b-row>
            <b-row align-h="center">
                <p>Upgrade to Stewardship license</p>
            </b-row>
            <b-row align-h="center">
                <a class="btn" @click="upgradeLicense()" v-if="!loading && !showError && !showSuccess">Upgrade</a>
                <b-spinner v-if="loading" label="Upgrading..."></b-spinner>
            </b-row>
        </b-container>
        <b-container v-if="showError">
            <p class="error">{{ errorMessage }}</p>
            <div v-if="status == 406">
                Redirecting to Alation in {{ counter }}
                {{ redirectToAlation() }}
            </div>
        </b-container>
        <b-container v-if="showSuccess">
            <p class="success">{{ successMessage }}</p>
            Redirecting to Alation in {{ counter }}
            {{ redirectToAlation() }}
        </b-container>
    </main>
</template>

<script>
import DataService from '../services/data.service';

const dataService = new DataService();

window.dataLayer = window.dataLayer || [];

export default {
    name: 'Home-Main', //Vue linter does not allow one-word component names
    data() {
        return {
            showError: false,
            showSuccess: false,
            errorMessage: "",
            successMessage: "",
            loading: false,
            redirect: "",
            status: 0,
            counter: 5
        }
    },
    methods: {
        upgradeLicense: function () {
            this.loading = true;
            
            dataService.upgradeLicense(this.$store.state.user.email)
                .then(response => {
                    this.loading = false;
                    if (response.status !== 200) {
                        this.errorMessage = response.data.errorMessage;
                        this.showError = true;
                        this.redirect = response.data.redirectUrl;
                        this.status = response.status;
                    }
                    else {
                        this.successMessage = response.data.successMessage;
                        this.showSuccess = true;
                        this.redirect = response.data.redirectUrl;
                        window.dataLayer.push({ 'event': 'formSubmitted', 'formName': 'Upgrade License' })
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.errorMessage = error.response.data.errorMessage;
                    this.showError = true;
                })
        },
        redirectToAlation() {
            // countdown, then redirect
            if (this.counter > 0) {
                setTimeout(() => { this.counter-- }, 1000);
            }
            if (this.counter == 0) {
                clearTimeout();
                window.location.replace(this.redirect);
            }
        }
    }
}

</script>

<style scoped>
.error {
    font-style: italic;
    color: #dc3545;
}

.success {
    font-style: italic;
    color: #28a745;
}

.notice {
    font-style: italic;
}

.sub-logo {
    width: auto;
    height: 55px;
}

.centerContent {
    align-items: center;
}

.icon {
    width: 32px;
    height: 32px;
}
</style>