import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/components/Home'
import error404 from '@/components/404'
import Logout from '@/components/Logout'
import { msalPluginInstance } from '@/plugins/MSALPlugin'
import { roles } from '@/authConfig'


const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home-Main',
      component: Home
    },
    {
      path: '/404-error',
      name: 'error-404',
      component: error404
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout
    },
    {
      path: '*',
      redirect: '/404-error'
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if (to.name == 'error-404') {
    next();
  } else if (to.name == 'Logout') {
    if (msalPluginInstance.isAuthenticated()) {
      // user logged in - log out
      msalPluginInstance.signOut();
    } else {
      next();
    }
  } else if (to.name == 'Login') {
    if(msalPluginInstance.isAuthenticated()) {
      // user logged in - send to home
      next({name: 'Home'}) 
    } else {
      // user not logged in
      await msalPluginInstance.signIn();
      next();
    }
  } else {
    await msalPluginInstance.signIn();
    if (msalPluginInstance.isAuthenticated()) {
      if(msalPluginInstance.isAuthorized(roles.ADMIN)) {
        next();
      } else {
        // no roles found - logout and send to access denied
        await msalPluginInstance.signOutAndAccessDenied();
      }
    } else {
      msalPluginInstance.signOut();
    }
  }
});

Vue.use(Router)

export default router