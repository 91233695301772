<template>
    <footer>
        <div class="wrapper-inner">
            <b-container>
                <b-row>
                    <b-col sm="6" class="terms1">
                        <div>&copy; {{ new Date().getFullYear() }} The Goodyear Tire &amp; Rubber Company</div>
                    </b-col>
                    <b-col sm="6" class="terms2">
                        <a href="https://corporate.goodyear.com/us/en/more/terms-conditions-and-privacy-policy.html"
                            target="_blank" rel="noopener">Terms &amp; Conditions and Privacy Policy</a>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer-Main' //Vue linter does not allow one-word component names
}
</script>

<style scoped>
footer {
    background-color: #004EA8;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 15px 15px 20px 15px;
    overflow: hidden;
    color: #fff;
    min-height: 100px;

    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}

a {
    color: #fff;
}

a:hover {
  color: #fd0;
}

.terms1 {
    text-align: left;
}

.terms2 {
    text-align: right;
}
</style>