const currentDomain = window.location.origin;
const customRedirectUri = currentDomain + '/';
const customLogoutRedirectUri = currentDomain + '/logout';


export const msalConfig = {
    auth: {
        clientId: "d50d5aa8-b548-4b08-aa30-fb48c073ef68", // This is the ONLY mandatory field that you need to supply.
        authority: "https://login.microsoftonline.com/939e8966-9285-4a9a-9f04-0887efe8aae0", // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: customRedirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: customLogoutRedirectUri, // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
}

export const msalScopes = ["api://56139221-fd0e-46cd-a99e-f865b154f88b/user_impersonation"]

export const roles = {
    ADMIN: "Alation_Catalog_Users"
}