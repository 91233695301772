<template>
    <header class="header">
            <b-container fluid class="wrapper-inner">
                <b-row>
                    <b-col class="headLeft">
                        <a href="https://goodyear.alationcloud.com/">
                            <img class="logo" src="../assets/images/Logo-One-Team.png" alt="One Team Logo" />
                        </a>
                    </b-col>
                    <b-col class="headRight">
                        <a v-if="$store.state.user.auth" @click="$msal.signOut()" href="#" target="_self"><b-icon-lock-fill></b-icon-lock-fill> Logout</a>
                    </b-col>
                </b-row>
            </b-container>
        </header>
</template>

<script>
export default {
    name: 'Header-Main' //Vue linter does not allow one-word component names
}
</script>

<style scoped>
.header {
    padding: 25px;
    width: 100%;
    background-color: #004EA8;
    color: #fff;
    font-family: "Barlow", Arial, sans-serif;
}

.logo {
    height: 45px;
    width: auto;
    padding-top: 5px;
    padding-left: 2%;
    margin: 10px;
  }
.headRight {
    text-align: right;
    margin-right: 1px;
    align-self: center;
}
.headLeft {
    text-align: left;
    margin-left: 1px;
}
a {
    color: #fff;
    font-family: "Barlow", Arial, sans-serif;
}

a:hover {
  color: #fd0;
}
</style>