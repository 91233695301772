<template>
  <div>
    <HeaderMain />
    <router-view/>
    <FooterMain />
  </div>
</template>

<script>
import HeaderMain from './components/Header.vue'
import FooterMain from './components/Footer.vue'
export default {
  name: "App",
  data() {
    return {
      value: "World"
    };
  },
  components: {HeaderMain,FooterMain}
};
</script>