import * as msal from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import store from '../../store'

let msalInstance;

export let msalPluginInstance;

export class MsalPlugin {
    #msalOptions = {};

    install(vue, options) {
        if (!options) {
            throw new Error("MsalPluginOptions must be specified");
        }
        this.#msalOptions = options;
        this.initialize();
        msalPluginInstance = this;
        vue.prototype.$msal = msalPluginInstance;
    }

    initialize() {
        const msalConfig = this.#msalOptions.msalConfig;
        msalConfig.system = {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case msal.LogLevel.Error:
                            return;
                        case msal.LogLevel.Info:
                            return;
                        case msal.LogLevel.Verbose:
                            return;
                        case msal.LogLevel.Warning:
                            return;
                    }
                },
                piiLoggingEnabled: false,
                logLevel: msal.LogLevel.Verbose
            }
        };
        msalInstance = new msal.PublicClientApplication(msalConfig);
    }

    async signIn() {
        const loginRequest = {
            scopes: this.#msalOptions.msalScopes
        };
        const redirectResponse = await msalInstance.handleRedirectPromise();
        // redirect promise fulfilled only after redirect
        // and getAllAccounts returns an Object also after redirect
        // so we need to get into signIn method twice: on login and after redirect
        if(!this.isAuthenticated()) {
            // no user signed in
            await msalInstance.loginRedirect(loginRequest);
            return false;
        } else {
            // update store auth
            store.commit("changeAuth", msalPluginInstance);
            if (redirectResponse) {
                this.storeUserAuth(redirectResponse.idToken, redirectResponse.account.username, redirectResponse.idTokenClaims.name);
            } else {
                const tokenResponse = await this.acquireTokenResponse();
                this.storeUserAuth(tokenResponse.idToken, tokenResponse.account.username, tokenResponse.idTokenClaims.name);
            }
        }
    }

    storeUserAuth(idToken, email, name) {
        // update store user
        store.commit("setUserIdToken", idToken);
        store.commit("setUserEmail", email);
        store.commit("setUserName", name);
    }

    async signOut() {
        await msalInstance.logout();
    }

    async signOutAndAccessDenied() {
        // sign user out and send to access denied post logout page
        await msalInstance.logoutRedirect({
            account: msalInstance.getAllAccounts()[0],
            postLogoutRedirectUri: window.location.origin + '/logout?error=access-denied'
        });
    }

    async acquireToken(callApi) {
        const redirectResponse = await msalInstance.handleRedirectPromise();
        if (redirectResponse !== null) {
            // acquire token silent success
            let accessToken = redirectResponse.accessToken;
            // call your API with token
           return await callApi(accessToken);
        } else {
            const accessTokenRequest = {
                account: msalInstance.getAllAccounts()[0],
                scopes: this.#msalOptions.msalScopes
            };

            try {
                let accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
                return await callApi(accessTokenResponse.accessToken);
            } catch(error) {
                // acquire token silent failure, and send an interactive request
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance.acquireTokenRedirect(accessTokenRequest);
                }
            }
        }
    }

    async acquireTokenResponse() {
        const accessTokenRequest = {
            account: msalInstance.getAllAccounts()[0],
            scopes: this.#msalOptions.apiScopes
        };

        try {
            return await msalInstance.acquireTokenSilent(accessTokenRequest);
        } catch(error) {
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(accessTokenRequest);
            }
        }
    }

    isAuthenticated() {
        const accounts = msalInstance.getAllAccounts();
        return accounts && accounts.length > 0;
    }

    isAuthorized(role) {
        let isAuthorized = false;
        const currentAccount = msalInstance.getAllAccounts()[0];
        if (currentAccount) {
            if (currentAccount.idTokenClaims.roles && currentAccount.idTokenClaims.roles.includes(role)) {
                isAuthorized = true;
            }
        }
        return isAuthorized;
    }
}